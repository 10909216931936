import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Circle.module.css';
import Tooltip from './Tooltip';

const SENTIMENTS = [
  { label: 'Bullish', color: '#00FF00', zIndex: 5 },
  { label: 'Neutral', color: '#ffff00', zIndex: 2 },
  { label: 'Bearish', color: '#F03E3E', zIndex: 4 },
];

const SentimentChart = ({ sentimentCounts, projectData }) => {
  const [hoveredSegment, setHoveredSegment] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const sentiments = SENTIMENTS.map((sentiment) => ({
    ...sentiment,
    count: sentimentCounts[sentiment.label.toLowerCase()] || 0,
  }));

  const total = sentiments.reduce((sum, { count }) => sum + count, 0);
  const radius = 42;
  const strokeWidth = 10;

  // Calculate start and end angles for each segment
  let startAngle = 0;
  const segmentData = sentiments
    .filter((segment) => segment.count > 0)
    .map((segment) => {
      const angle = (segment.count / total) * 360;
      const endAngle = startAngle + angle;
      const data = { ...segment, startAngle, endAngle };
      startAngle = endAngle;
      return data;
    })
    .sort((a, b) => a.zIndex - b.zIndex);

  const createArc = (startAngle, endAngle) => {
    const start = polarToCartesian(50, 50, radius, startAngle);
    const end = polarToCartesian(50, 50, radius, endAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
    return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${end.x} ${end.y}`;
  };

  const handleMouseEnter = (segment, event) => {
    setHoveredSegment(segment);
    setTooltipPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseLeave = () => {
    setHoveredSegment(null);
  };

  const nonZeroSentiments = sentiments.filter((segment) => segment.count > 0);
  const singleSentiment = nonZeroSentiments.length === 1 ? nonZeroSentiments[0] : null;

  return (
    <div className={styles.container}>
      <svg width='193' height='193' viewBox='0 0 100 100'>
        <circle cx='50' cy='50' r={radius + strokeWidth / 2} fill='black' />
        {singleSentiment ? (
          <circle
            cx='50'
            cy='50'
            r={radius}
            fill='none'
            stroke={singleSentiment.color}
            strokeWidth={strokeWidth}
            onMouseEnter={(e) => handleMouseEnter(singleSentiment, e)}
            onMouseLeave={handleMouseLeave}
          />
        ) : (
          segmentData.map((segment) => (
            <path
              key={segment.label}
              d={createArc(segment.startAngle, segment.endAngle)}
              fill='none'
              stroke={segment.color}
              strokeWidth={strokeWidth}
              strokeLinecap='round'
              onMouseEnter={(e) => handleMouseEnter(segment, e)}
              onMouseLeave={handleMouseLeave}
            />
          ))
        )}
        <circle cx='50' cy='50' r='25' fill='black' />
        <image className={styles.circleLogo} href='/images/circleLogo.svg' x='23' y='23' width='54' height='54' />
      </svg>
      <div className={styles.legend}>
        {sentiments.map(({ label, color, count }) => (
          <div key={label} className={styles.legendItem}>
            <div className={styles.legendColor} style={{ backgroundColor: color }}></div>
            <span className={styles.legendLabel} style={{ color: color }}>{`${label} (${count})`}</span>
          </div>
        ))}
      </div>
      {hoveredSegment && (
        <Tooltip 
          sentiment={hoveredSegment.label} 
          projectData={projectData} 
          position={tooltipPosition} 
        />
      )}
    </div>
  );
};

SentimentChart.propTypes = {
  sentimentCounts: PropTypes.shape({
    bullish: PropTypes.number,
    neutral: PropTypes.number,
    bearish: PropTypes.number,
  }).isRequired,
  projectData: PropTypes.object.isRequired,
};

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

export default SentimentChart;
