import React, { useState, useEffect, useCallback, useContext } from "react";
import PerformanceChart from "./PerformanceChart.js";
import styles from "./HistoricalOverview.module.css";
import Loader from "../Loader/Loader.js";
import Tooltip from "../Tooltip/Tooltip.js";
import { useDisconnect } from "@web3modal/ethers/react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper.js";
import { getNextHourTimestamp } from "../../utils/helpers.js";
import Dropdown from "../Dropdown/Dropdown.jsx";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

function HistoricalOverview({
  // onDataFetched,
  selectedModel,
  selectedProjectId,
  onModelProjectChange,
  dashboardState,
  // onStrategyFilterChange
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [data, setData] = useState();
  const [models, setModels] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const { state, send } = useContext(AuthContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (dashboardState) {
      setModels(Object.keys(dashboardState));
      setProjectIds(
        dashboardState[selectedModel]
          ? Object.keys(dashboardState[selectedModel])
          : []
      );
    }
  }, [dashboardState, selectedModel]);

  const fetchData = async () => {
    try {
      const result = state.context.strategyHistory;
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedModel &&
      selectedProjectId &&
      dashboardState &&
      state.context?.strategyHistory
    ) {
      setIsLoading(true);
      fetchData();
    }
  }, [
    dashboardState,
    selectedModel,
    selectedProjectId,
    state.context?.strategyHistory,
  ]);

  const refreshStrategyHistory = () => {
    if (selectedModel && selectedProjectId && state.matches("strategy.idle")) {
      send({ type: "REFRESH_STRATEGY_HISTORY" });
    }
  };

  // useEffect(() => {
  //   refreshStrategyHistory();
  // }, [selectedModel, selectedProjectId, state.context.strategyStateFilter]);

  useEffect(() => {
    if (typeof onModelProjectChange === "function") {
      const currentProjectId = projectIds.includes(selectedProjectId)
        ? selectedProjectId
        : projectIds[0] || selectedProjectId;
      if (models.length > 0 && !models.includes(selectedModel)) {
        // If the selected model is not in the list, select the first model and project
        onModelProjectChange(models[0], currentProjectId);
      } else if (
        selectedModel &&
        (!selectedProjectId || !projectIds.includes(selectedProjectId))
      ) {
        // If a model is selected but no project ID is selected (or the selected project ID is invalid),
        // keep the current model and select the first project for that model
        onModelProjectChange(selectedModel, currentProjectId);
      }
    }
  }, [
    models,
    selectedModel,
    projectIds,
    selectedProjectId,
    onModelProjectChange,
  ]);

  const handleDropdownSelect = (option) => {
    onStrategyFilterChange({ tf: option });
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onStrategyFilterChange = useCallback(
    (strategyStateFilter) => {
      if (state.matches("strategy.idle")) {
        send({
          type: "UPDATE_MODEL_AND_PROJECT",
          payload: {
            type: "strategy",
            strategyStateFilter: {
              ...strategyStateFilter,
            },
            projectId: selectedProjectId,
          },
        });
      }
    },
    [state.value]
  );

  const toggleProjectDropdown = () =>
    setIsProjectDropdownOpen(!isProjectDropdownOpen);
  const toggleModelDropdown = () =>
    setIsModelDropdownOpen(!isModelDropdownOpen);

  const selectProjectId = (projectId) => {
    onModelProjectChange(selectedModel, projectId);
    setIsProjectDropdownOpen(false);
  };

  const selectModel = (model) => {
    const currentProjectId = Object.keys(dashboardState[model]).includes(
      selectedProjectId
    )
      ? selectedProjectId
      : Object.keys(dashboardState[model])[0];
    onModelProjectChange(model, currentProjectId);
    setIsModelDropdownOpen(false);
  };

  const handleSizeChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value >= 0 && value <= 1) {
      onStrategyFilterChange({ size: value });
    }
  };

  const handleThresholdChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value >= 1 && value <= 3) {
      onStrategyFilterChange({ threshold: value });
    }
  };

  const toggleAllowShort = (event) => {
    onStrategyFilterChange({ allow_short: event.target.checked });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.overviewHeader}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>Historical Overview</div>
          <Tooltip id="historical-overview" page="strategy-page" />
        </div>
        <div className={styles.settingsContainer}>
        <div className={styles.mainDropdownContainer}>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              columnGap: 10,
              alignItems: "center",
            }}
          >
            <Dropdown
              selectedOption={selectedModel}
              options={models}
              onSelect={selectModel}
              isOpen={isModelDropdownOpen}
              toggleDropdown={toggleModelDropdown}
            />

            <Dropdown
              selectedOption={selectedProjectId}
              options={projectIds}
              onSelect={selectProjectId}
              isOpen={isProjectDropdownOpen}
              toggleDropdown={toggleProjectDropdown}
            />

            {/* Timeframe Dropdown */}
            {/* <Dropdown
            selectedOption={timeframe}
            options={ Object.keys(timeframeMapping)}
            onSelect={handleTimeframeChange}
            isOpen={isTimeframeDropdownOpen}
            toggleDropdown={toggleTimeframeDropdown}
          /> */}
            <Dropdown
              selectedOption={state.context?.strategyStateFilter?.tf}
              options={state.context?.projectIds?.tf}
              onSelect={handleDropdownSelect}
              isOpen={isDropdownOpen}
              toggleDropdown={toggleDropdown}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              columnGap: 10,
              alignItems: "center",
            }}
          >
            <label>Size: </label>

            <TextField
              id="size-input"
              // label="Size"
              type="number"
              variant="standard"
              value={state.context.strategyStateFilter?.size}
              onChange={handleSizeChange}
              inputProps={{
                min: 0,
                max: 1,
                step: 0.1,
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1,
                  step: 0.1,
                },
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "white", // White label
                },
                "& .MuiInput-root": {
                  color: "white", // White text
                  "&:before": {
                    borderBottomColor: "white", // White bottom border
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "white", // White bottom border on hover
                  },
                  "&.Mui-focused:after": {
                    borderBottomColor: "white", // White bottom border when focused
                  },
                },
                "& .MuiInput-input": {
                  color: "white", // Ensure input text is white
                },
              }}
            />

            {/* Threshold Input */}
            <label>Threshold: </label>
            <TextField
              id="threshold-input"
              // label="Threshold"
              type="number"
              variant="standard"
              value={state.context.strategyStateFilter?.threshold}
              onChange={handleThresholdChange}
              inputProps={{
                min: 1,
                max: 3,
                step: 0.1,
              }}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 3,
                  step: 0.1,
                },
              }}
              // slotProps={{
              //   input: () => ( {
              //     min: 1,
              //     max: 3,
              //     step: 0.1
              //   }),
              // }}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "white", // White label
                },
                "& .MuiInput-root": {
                  color: "white", // White text
                  "&:before": {
                    borderBottomColor: "white", // White bottom border
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "white", // White bottom border on hover
                  },
                  "&.Mui-focused:after": {
                    borderBottomColor: "white", // White bottom border when focused
                  },
                },
                "& .MuiInput-input": {
                  color: "white", // Ensure input text is white
                },
              }}
            />

            <div className={styles.toggleContainer}>
              <label>Allow Short:</label>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={state.context.strategyStateFilter?.allow_short}
                onChange={toggleAllowShort}
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonWallet} onClick={refreshStrategyHistory} >
              {/* <img src='/images/walletIcon.svg' alt='Wallet' /> */}
              Generate
            </div>
      </div>
      </div>
     

      <div className={styles.chartContainer}>
        <div className={styles.chartBG}>
          <img src="images/chartBG.png" />
        </div>
        {state.matches("strategy.loading") && <Loader />}

        <PerformanceChart data={data} />
      </div>
    </div>
  );
}

export default HistoricalOverview;
