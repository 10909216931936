import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper.js";
import Dropdown from "../Dropdown/Dropdown.jsx";
import Loader from "../Loader/Loader.js";
import Tooltip from "../Tooltip/Tooltip.js";
import styles from "./HistoricalOverview.module.css";
import PerformanceChart from "./PerformanceChart";
import { useSearchParams } from "react-router-dom";

function HistoricalOverviewModel({
  dashboardState,
  initialModel,
  initialProjectId,
}) {
  const [uiState, setUiState] = useState({
    isLoading: false,
    isProjectDropdownOpen: false,
    isModelDropdownOpen: false,
  });

  const [params] = useSearchParams();
  const { state, send } = useContext(AuthContext);

  const [chartData, setChartData] = useState(
    state?.context?.modelHistory?.close?.length
      ? {
          ...state.context.modelHistory,
        }
      : {
          close: [],
          model_timestamps: [],
          close_timestamps: [],
          pred_price: [],
        }
  );

  const [selections, setSelections] = useState({
    models: [],
    projectIds: [],
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const selectInitialProjectId = useCallback(
    (availableProjectIds, initial) => {
      if (initial && availableProjectIds.includes(initial)) {
        return initial;
      }

      if (
        state.context?.selectedIds?.historical &&
        availableProjectIds.includes(state.context.selectedIds.historical)
      ) {
        return state.context.selectedIds.historical;
      }

      return availableProjectIds[0];
    },
    [state.context?.selectedIds?.historical]
  );

  // // Memoized chart data
  // useEffect(() => {
  //   setChartData((prevChartData) => {
  //     const newChartData = state?.context?.modelHistory?.close?.length
  //       ? { ...state.context.modelHistory }
  //       : {
  //           close: [],
  //           model: [],
  //           model_timestamps: [],
  //           close_timestamps: [],
  //           predicted_price: [],
  //         };

  //     // console.table("Previous chartData:", prevChartData);
  //     // console.table("New chartData:", newChartData);

  //     return newChartData;
  //   });
  // }, [state.context.modelHistory]);

  useEffect(() => {
    if (!dashboardState) {
      setSelections({ models: [], projectIds: [] });
      return;
    }
    const availableModels = Object.keys(dashboardState);
    const availableProjectIds = state.context.projectIds?.uniqueProjectIds;
    setSelections({
      models: availableModels,
      projectIds: availableProjectIds,
    });

    // const newProjectIds = Object.keys(state.context?.modelState[initialModel]) || [];
    const preferredProjectId = selectInitialProjectId(
      availableProjectIds,
      params.get("projectID")
    );

    updateModelAndProject(initialModel, preferredProjectId);
    
  }, [dashboardState, initialModel, initialProjectId]);

  useEffect(() => {
    const shouldFetchData =
      state?.context?.selectedHistoricalModel &&
      state.context?.selectedIds?.historical &&
      !!state.context?.accessToken;
    if (shouldFetchData && !state.matches("idle")) {
      send({ type: "REFRESH_MODEL_HISTORY" });
    }

    // Update the previous model reference
  }, [
    state?.context?.selectedHistoricalModel,
    state.context?.selectedIds?.historical,
    state.context?.modelStateFilter?.tf,
    state.context?.accessToken,
  ]);

  const updateModelAndProject = useCallback(
    (model, projectId) => {
      send({
        type: "UPDATE_MODEL_AND_PROJECT",
        payload: {
          model,
          type: "historical",
          projectId,
        },
      });
    },
    [send]
  );

  const updateInitialModel = useCallback(
    (model) => {
      send({
        type: "UPDATE_MODEL_AND_PROJECT",
        payload: {
          model,
        },
      });
    },
    [send]
  );

  const handleModelSelect = useCallback(
    (model) => {
      const newProjectIds = Object.keys(state.context?.modelState[model]) || [];
      const preferredProjectId = selectInitialProjectId(newProjectIds);

      setSelections((prev) => ({ ...prev, projectIds: newProjectIds }));
      updateModelAndProject(model, preferredProjectId);
      setUiState((prev) => ({ ...prev, isModelDropdownOpen: false }));
    },
    [
      dashboardState,
      state?.context?.selectedHistoricalModel,
      state.context?.selectedIds?.historical,
    ]
  );

  const handleProjectSelect = useCallback(
    (projectId) => {
      send({
        type: "UPDATE_MODEL_AND_PROJECT",
        payload: {
          model: state?.context?.selectedHistoricalModel,
          type: "historical",
          projectId,
        },
      });
      setUiState((prev) => ({ ...prev, isProjectDropdownOpen: false }));
    },
    [state?.context?.selectedHistoricalModel]
  );

  const toggleDropdown = useCallback((dropdownType) => {
    setUiState((prev) => ({
      ...prev,
      [dropdownType]: !prev[dropdownType],
    }));
  }, []);

  const onModelFilterChange = useCallback(
    (modelStateFilter) => {
      if (state.matches("model.idle")) {
        send({
          type: "UPDATE_MODEL_AND_PROJECT",
          payload: {
            modelStateFilter: {
              ...modelStateFilter,
            },
          },
        });
      }
    },
    [state.value]
  );

  const handleDropdownSelect = (option) => {
    // Call onModelFilterChange if needed
    onModelFilterChange({ tf: option });
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.overviewHeader}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>Historical Overview</div>
          <Tooltip id="historical-overview" page="model-page" />
        </div>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            columnGap: 10,
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Dropdown
            selectedOption={state?.context?.selectedHistoricalModel}
            options={selections.models}
            onSelect={handleModelSelect}
            isOpen={uiState.isModelDropdownOpen}
            toggleDropdown={() => toggleDropdown("isModelDropdownOpen")}
          />

          <Dropdown
            selectedOption={state.context?.selectedIds?.historical}
            options={selections.projectIds}
            onSelect={handleProjectSelect}
            isOpen={uiState.isProjectDropdownOpen}
            toggleDropdown={() => toggleDropdown("isProjectDropdownOpen")}
          />

          <Dropdown
            selectedOption={state.context?.modelStateFilter?.tf}
            options={state.context?.projectIds?.tf}
            onSelect={handleDropdownSelect}
            isOpen={isDropdownOpen}
            toggleDropdown={() => setIsDropdownOpen(!isDropdownOpen)}
          />
        </div>
      </div>

      <div className={styles.chartContainer}>
        <div className={styles.chartBG}>
          <img src="images/chartBG.png" />
        </div>
        {state.matches("model.loading") && (
          <div>
            <Loader />
          </div>
        )}
        <PerformanceChart
          data={
            state?.context?.modelHistory?.close?.length
              ? {
                  ...state.context.modelHistory,
                }
              : {
                  close: [],
                  timestamp: [],
                  pred_price: [],
                  residual: [],
                }
          }
        />
      </div>
    </div>
  );
}

export default React.memo(HistoricalOverviewModel);
