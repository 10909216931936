import { useDisconnect } from "@web3modal/ethers/react";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./StrategyOverview.module.css";
import { fixToTwoDecimals } from "../../utils/helpers";
import {PERSIST_KEY} from "../../constants//constants.js"
// import Dropdown from "../Dropdown/Dropdown";

const STRATEGY_OVERVIEW_CONFIG = {
  metrics: {
    title: "Metrics",
    items: [
      { label: "Sharpe", param: "sharpe", type: "sharpe", format: "decimal" },
      { label: "Sortino", param: "sortino", type: "sortino", format: "decimal" },
      { label: "Win Rate", param: "winrate", type: "winrate", format: "percent" },
      { label: "Max Drawdown", param: "max_dd", type: "maxdd", format: "percent" },
    ],
  },
  returns: {
    title: "Returns",
    items: [
      { label: "Last Month", param: "1m_return", type: "return", format: "percent" },
      { label: "Last Quarter", param: "1q_return", type: "return", format: "percent" },
      { label: "Total", param: "total_return", type: "return", format: "percent" },
      { label: "Avg Daily", param: "avg_daily_return", type: "return", format: "percent" },
    ],
  },
  others: {
    title: "Others",
    items: [
      { label: "Trades", param: "trades", type: "default", format: "number" },
      { label: "Avg Win", param: "avg_win", type: "avgValue", format: "percent" },
      { label: "Avg Loss", param: "avg_loss", type: "avgValue", format: "percent" },
      { label: "Profit Factor", param: "profit_factor", type: "profitFactor", format: "decimal" },
    ],
  },
};

const StrategyOverview = ({
  selectedModel,
  selectedProjectId,
  dashboardState,
}) => {
  const [strategyHistory, setStrategyHistory] = useState(null);
  const { disconnect } = useDisconnect();
const { state, send } = useContext(AuthContext);

  useEffect(() => {
    const fetchstrategyHistory = async () => {
      try {
        const response = state.context.strategyHistory;

        setStrategyHistory(response);

      } catch (error) {
          if (error.response && error.response.status === 401) {
            send({ type: 'SIGN_OUT' });
            localStorage.removeItem(PERSIST_KEY);
            disconnect();
          }
        console.error("Error fetching strategy state:", error);
      }
    };

    if(dashboardState && selectedModel && selectedProjectId && state.context.strategyHistory) {
      fetchstrategyHistory();
    }
  }, [selectedModel, selectedProjectId, dashboardState, state.context.strategyHistory]);

  const getColor = (value, type) => {
    if (!value && value !== 0) return '#fff';

    switch (type) {
      case 'sharpe':
      case 'sortino':
        return value > 1 ? '#00ff00' : value < 0 ? '#ff0000' : 'yellow';
      case 'winrate':
        return value > 55 ? '#00ff00' : value < 45 ? '#ff0000' : 'yellow';
      case 'maxdd':
        return value > -20 ? '#00ff00' : value < -30 ? '#ff0000' : 'yellow';
      case 'return':
        return value > 0 ? '#00ff00' : value < 0 ? '#ff0000' : 'yellow';
      case 'avgValue':
        return (strategyHistory?.stats?.avg_win || 0) > Math.abs(strategyHistory?.stats?.avg_loss || 0) 
          ? '#00ff00' 
          : '#ff0000';
      case 'profitFactor':
        return value > 1.5 ? '#00ff00' : value < 1 ? '#ff0000' : 'yellow';
      default:
        return '#fff';
    }
  };

  const formatValue = (value, format) => {
    if (!value && value !== 0) return "--";
    switch (format) {
      case "percent":
        return `${fixToTwoDecimals(value)}%`;
      case "decimal":
        return fixToTwoDecimals(value);
      case "number":
        return value;
      default:
        return value;
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.headerLayout}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Strategy Overview</div>
            <Tooltip id='strategy-overview' page='strategy-page' />
          </div>{' '}
          {/* <Dropdown
            selectedOption={selectedOption}
            options={["high", "low"]}
            onSelect={handleDropdownSelect}
            isOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
          /> */}
        </div>
        <div className={styles.dashboard}>
          {Object.values(STRATEGY_OVERVIEW_CONFIG).map((panel) => (
            <div key={panel.title} className={styles.panel}>
              <div className={styles.panelTitle}>{panel.title}</div>
              <div className={styles.panelContent}>
                {panel.items.map((item) => (
                  <div key={item.param} className={styles.dataRow}>
                    <span className={styles.dataLabel}>{item.label}</span>
                    <span
                      className={styles.dataValue}
                      style={{
                        color: item.type !== 'default' ? getColor(strategyHistory?.stats?.[item.param], item.type) : '#fff',
                      }}>
                      {formatValue(strategyHistory?.stats?.[item.param], item.format)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StrategyOverview;