import React, { useContext, useEffect, useState } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import ConnectModal from "../ConnectModal/ConnectModal";
import { useErrorContext } from "../../context/ErrorContext";
import SubscriptionModal from "../SubscriptionModal/SubscriptionModal";
import InsufficientBalanceModal from "../InsufficientBalanceModal/InsufficientBalanceModal";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";

const withAuthenticationGuard = (WrappedComponent) => {
  return function WithAuthenticationGuard(props) {
    const { isConnected } = useWeb3ModalAccount();
    const { error } = useErrorContext();
    const { state, send } = useContext(AuthContext);

    if ((!isConnected && state.matches("idle"))  || state.matches("signingIn") ) {
      return (
        <>
          <ConnectModal />
          <WrappedComponent {...props} />

        </>
      );
    } else if (state.matches("verifyingTransaction")) {
      return (
        <>
          <SubscriptionModal />
          <WrappedComponent {...props} />
        </>
      );
    } else if (state.matches("waitingForSubscription")) {
      return (
        <>
          <InsufficientBalanceModal />
          <WrappedComponent {...props} />
        </>
      );
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuthenticationGuard;