import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { AuthContext } from '../AuthWrapper/AuthWrapper.js';
import withAuthenticationGuard from '../components/guards/withAuthWrapper';
import HistoricalOverview from '../components/HistoricalOverview/HistoricalOverview';
import StrategyOverview from '../components/StrategyOverview/StrategyOverview';
import Trades from '../components/Trades/Trades';
import withLayout from '../components/withLayout/withLayout';
import PerformanceHeatmap from '../components/PerformanceHeatmap/PerformanceHeatmap';
import { DEFAULT_MODEL } from '../constants/constants.js';
const Strategy = ({ fullWidth }) => {
  // const [performanceData, setPerformanceData] = useState(null);

  const { isConnected } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);

  const isLoading =
    state.matches('idle') || state.matches('signingIn') || state.matches('waitingForSubscription') || state.matches('verifyingTransaction')   ||  state.matches("loggingOut");;

  // const handleDataFetched = useCallback((data) => {
  //   setPerformanceData(data);
  // }, []);

  useEffect(() => {
    if (state.matches('model') || state.matches('home') || state.matches('strategy') || state.matches('apiKey')) send({ type: 'CHECK_ROUTE' });
  }, [send]);

  const RenderLoadingOverlay = () => <div className='loading-overlay' />;

  const handleModelProjectChange = useCallback(
    (model, projectId, strategyStateFilter, clearStrategyHistory = false) => {
      if (state.matches('strategy.idle')) {
        send({
          type: 'UPDATE_MODEL_AND_PROJECT',
          payload: {
            type: 'strategy',
            strategyStateFilter: {
              ...strategyStateFilter,
            },
            model,
            projectId,
          },
        });
      }
    },
    [state.value]
  );

  // this is to make initianl model oracle

  useEffect(() => {
    send({
      type: 'UPDATE_MODEL_AND_PROJECT',
      payload: {
        model: DEFAULT_MODEL,
      },
    });
  }, [send]);

  const renderComponents = (data) => (
    <>
      <HistoricalOverview
        // onDataFetched={data ? handleDataFetched : null}
        key={fullWidth}
        selectedModel={data ? state?.context?.selectedHistoricalModel : null}
        selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
        onModelProjectChange={data ? handleModelProjectChange : null}
        dashboardState={data ? state.context.dashboardState : null}
        // onStrategyFilterChange={data ?  handleStrategyFilterChange : null }
      />
      <StrategyOverview
        // performanceData={data ? performanceData : null}
        selectedModel={data ? state?.context?.selectedHistoricalModel : null}
        selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
        dashboardState={data ? state.context.dashboardState : null}
      />
      <Trades
        dashboardState={data ? state.context.dashboardState : null}
        selectedModel={data ? state?.context?.selectedHistoricalModel : null}
        selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
      />
      <PerformanceHeatmap
      // dashboardState={data ? state.context.dashboardState : null}
      // selectedModel={data ? state?.context?.selectedHistoricalModel : null}
      // selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
      />
    </>
  );

  return (
    <>
      {isConnected ? (
        renderComponents(state.context.dashboardState)
      ) : (
        <>
          {renderComponents(null)}
        </>
      )}
      {isLoading && <RenderLoadingOverlay />}
    </>
  );
};

export default withAuthenticationGuard(Strategy);
