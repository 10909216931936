import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tooltip.module.css';
import { fixToTwoDecimals } from '../../utils/helpers';

const Tooltip = ({ sentiment, projectData, position }) => {
  return (
    <div
      className={styles.tooltip}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}>
      <h3>{sentiment} Models</h3>
      <ul>
        {Object.entries(projectData || {})
          .filter(([_, data]) => data.sentiment.toLowerCase() === sentiment.toLowerCase())
          .map(([model, data]) => (
            <li key={model}>
              {model}: {fixToTwoDecimals(data.z_residual, 2)}
            </li>
          ))}
      </ul>
    </div>
  );
};

Tooltip.propTypes = {
  sentiment: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired,
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
};

export default Tooltip;
