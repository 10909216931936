import React, { useState, useEffect, useMemo } from 'react';
import GaugeComponent from 'react-gauge-component';
import styles from './Gauge.module.css';

const CustomGauge = React.memo(({ residual }) => {
  const [gaugeValue, setGaugeValue] = useState(0);
  const [threshold, setThreshold] = useState(1); // Default threshold to avoid division by zero
  const [animate, setAnimate] = useState(false);
  //console.log("gaugeValue", gaugeValue);
  const getColor = (value) => {
    if (value <= -3) return '#8B0000';
    if (value <= -1.5) return '#FF4500';
    if (value >= 1.5) return '#90EE90';
    if (value >= 3) return '#006400';
    return '#FED700';
  };
  useEffect(() => {
    if (residual?.config?.strategy?.threshold) {
      const threshold = residual.config.strategy.threshold;
      setThreshold(threshold);
    }

    if (residual?.z_residual !== undefined) {
      const currentThreshold = residual.config?.strategy?.threshold || threshold;
      const newValue = Math.max(-3 * currentThreshold, Math.min(residual.z_residual, 3 * currentThreshold));
      setGaugeValue(newValue);
      setAnimate(true);
    }
  }, [residual]);

  // const getLabel = (value) => {
  //   if (value < -2 * threshold) return "Strong Sell";
  //   if (value < -1 * threshold) return "Sell";
  //   if (value > 1 * threshold && value < 2 * threshold) return "Buy";
  //   if (value >= 2 * threshold) return "Strong Buy";
  //   return "Neutral";
  // };

  const getLabel = (value) => {
    let threshold = residual?.config?.strategy?.threshold;

    if (threshold === undefined || 0) {
      threshold = 1;
    }

    if (value < -2 * threshold)
      return {
        label: 'Strong Sell',
        subLabel: 'Consider reducing positions',
      };
    if (value < -1 * threshold) return { label: 'Sell', subLabel: 'Review your portfolio' };
    if (value > 1 * threshold && value < 2 * threshold) return { label: 'Buy', subLabel: 'Look for opportunities' };
    if (value >= 2 * threshold) return { label: 'Strong Buy', subLabel: 'High confidence in growth' };
    return { label: 'Neutral', subLabel: 'You should wait a little' };
  };

  // const gaugeLabel = getLabel(gaugeValue);
  const { label: gaugeLabel, subLabel } = getLabel(gaugeValue);

  const [margin, setMargin] = useState(null);
  const [gaugeWidth, setGaugeWidth] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      //console.log(currentWidth);

      if (currentWidth < 480) {
        setGaugeWidth(380);
        setMargin({ top: 0.1, bottom: 0.0, left: 0.282, right: 0.282 });
      } else if (currentWidth < 580) {
        setGaugeWidth(380);
        setMargin({ top: 0.1, bottom: 0.0, left: 0.212, right: 0.212 });
      } else if (currentWidth < 1540) {
        setGaugeWidth(380);
        setMargin({ top: 0.1, bottom: 0.0, left: 0.192, right: 0.192 });
      } else if (currentWidth < 1640) {
        setGaugeWidth(480);
        setMargin({ top: 0.1, bottom: 0.0, left: 0.15, right: 0.15 });
      } else {
        setGaugeWidth(620);
        setMargin({ top: 0.1, bottom: 0.0, left: 0.113, right: 0.113 });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={styles.gaugeContainer}>
      <div className={styles.gaugeWrapper}>
        {margin && gaugeWidth && (
          <GaugeComponent
            type='semicircle'
            marginInPercent={margin}
            arc={{
              width: 0.15,
              padding: 0.0,
              subArcs: [
                {
                  limit: gaugeValue,
                  color: getColor(gaugeValue),
                  showTick: false,
                },
                {
                  limit: threshold * 3.1,
                  color: '#4D5149',
                  showTick: true,
                },
              ],
            }}
            pointer={{
              color: '#FFFFFF',
              length: 0.5,
              width: 10,
              animate: animate,
            }}
            labels={{
              valueLabel: {
                hide: true,
                // formatTextValue: (value) => getLabel(value),
                formatTextValue: (value) => getLabel(value).label,
              },
              tickLabels: {
                type: 'outer',
                defaultTickValueConfig: {
                  style: {
                    fontSize: '16px',
                    fill: '#FFF',
                    opacity: '0.5',
                    width: '10px',
                  },
                  // formatTextValue: (value) => getLabel(value),
                  formatTextValue: (value) => getLabel(value).label,
                },
                defaultTickLineConfig: {
                  length: 15,
                  width: 2,
                },
                ticks: [{ value: -threshold * 1.5 }, { value: 0 }, { value: threshold * 1.5 }],
              },
            }}
            value={gaugeValue}
            minValue={-threshold * 3.1}
            maxValue={threshold * 3.1}
          />
        )}
      </div>

      <div className={styles.gaugeLabel}>{gaugeLabel}</div>
      <div className={styles.gaugeSubtext}>{subLabel}</div>
    </div>
  );
});

export default CustomGauge;
