import { useDisconnect } from '@web3modal/ethers/react';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../AuthWrapper/AuthWrapper';
import { MARKET_OVERVIEW_INIT, TRADES_STRATEGY } from '../../constants/constants';
import Tooltip from '../Tooltip/Tooltip';
import styles from './Trades.module.css';
import { fixToTwoDecimals } from '../../utils/helpers';
import {PERSIST_KEY} from "../../constants/constants.js"
function Trades({ dashboardState, selectedModel, selectedProjectId }) {
  const [models, setModels] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableClass, setTableClass] = useState("");
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);
  const [data, setData] = useState(MARKET_OVERVIEW_INIT);
  const [sortConfig, setSortConfig] = useState({
    key: "timestamp",
    direction: "desc",
  });
  // const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (dashboardState) {
      const collections = Object.keys(dashboardState);
      setModels(collections);
      if (collections.length > 0) {
        const initialModel = collections[0];
        setProjectIds(dashboardState[initialModel] || []);
      }
    } else {
      setModels([]);
      setProjectIds([]);

      setData(MARKET_OVERVIEW_INIT);
    }
  }, [dashboardState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = state.context?.strategyHistory;
        const sortedTrades = result.trades.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setData(sortedTrades);
        setTableClass(sortedTrades.length > 10 ? styles.largeTable : "");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          send({ type: "SIGN_OUT" });
          localStorage.removeItem(PERSIST_KEY);
          disconnect();
        }
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (state.context?.strategyHistory) fetchData();
  }, [state.context?.strategyHistory]);

  const sortData = (key, direction = "desc", dataToSort = data) => {
    const sortedData = [...dataToSort].sort((a, b) => {
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const toggleSortDirection = (key) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }
    sortData(key, direction);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <img
          src="/images/tableSortArrow.svg"
          alt="Sort ascending"
          className={styles.sortIcon}
        />
      ) : (
        <img
          src="/images/tableSortArrow.svg"
          alt="Sort descending"
          className={`${styles.sortIcon} ${styles.inverted}`}
        />
      );
    }
    return (
      <img
        src="/images/tableSortArrow.svg"
        alt="Sort"
        className={`${styles.sortIcon} ${styles.inactive}`}
      />
    );
  };

  const columns = TRADES_STRATEGY;


  const formatValue = (key, value) => {

    if (value === "--" || value === null || value === undefined) return "--";

    if (key === "timestamp") {
      const date = new Date(value);
      return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
    }

    if (key === "price") {
      return `$${fixToTwoDecimals(value, 2)}`;
    }

    if (key === "position_delta") {
      return `${fixToTwoDecimals(value, 2, true)}%`;
    }

    if (key === "position") {
      return `${fixToTwoDecimals(value, 1, false)}`;
    }
    return value;
  };

  const getSideClass = (positionChange) => {
    return positionChange > 0 ? styles.buy : styles.sell;
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Trades</div>
            <Tooltip id="trades" page="strategy-page" />
          </div>
        </div>
        <div className={`${styles.tableContainer} ${tableClass}`}>
          <table className={styles.table}>
            <thead>
              <tr>
                {columns.map(({ key, label, sortable }) => (
                  <th
                    key={key}
                    onClick={
                      sortable ? () => toggleSortDirection(key) : undefined
                    }
                    aria-sort={
                      sortConfig.key === key ? sortConfig.direction : "none"
                    }
                  >
                    {label} {sortable && getSortIcon(key)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className={styles.tableRow}>
                  {columns.map(({ key }) => (
                    <td
                      key={key}
                      className={
                        key === "position_delta" ? getSideClass(item[key]) : ""
                      }
                    >
                      {key === "side" ? (
                        item.position_delta === "--" ||
                        item.position_delta === null ||
                        item.position_delta === undefined ? (
                          "--"
                        ) : (
                          <span className={getSideClass(item.position_delta)}>
                            {item.position_delta > 0 ? "BUY" : "SELL"}
                          </span>
                        )
                      ) : (
                        formatValue(key, item[key])
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Trades;