import React, { useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthWrapper/AuthWrapper';
import { PH_GENERAL_OVERVIEW } from '../../constants/constants';
import { useModelContext } from '../../ContextProvider';
import { extractProjectIds } from '../../utils/helpers';
import Tooltip from '../Tooltip/Tooltip';
import styles from './GeneralOverview.module.css';

function GeneralOverview({ dashboardState }) {
  const [isOpen, setIsOpen] = useState(false);
  const { setModel, setProjectID } = useModelContext();
  const [data, setData] = useState(PH_GENERAL_OVERVIEW);
  const [selectedTf, setSelectedTf] = useState('high');
  const [isTimeframeOpen, setIsTimeframeOpen] = useState(false);

  const { state, send } = useContext(AuthContext);

  useEffect(() => {
    if (dashboardState && !state.context?.selectedIds?.general) {
      const { uniqueProjectIds, tf } = extractProjectIds(dashboardState);
      // console.log(uniqueProjectIds, tf);

      send({
        type: 'UPDATE_MODEL_AND_PROJECT',
        payload: {
          type: 'general',
          projectId: uniqueProjectIds[0],
        },
      });
    } else if (!state.context?.modelState || Object.keys(state.context?.modelState).length === 0) {
      setData(PH_GENERAL_OVERVIEW);
    }
  }, [dashboardState, state.value]);

  useEffect(() => {
    if (state.context?.selectedIds?.general && state.context?.modelState && Object.keys(state.context.modelState).length > 0) {
      fetchData();
    }
  }, [state.context?.selectedIds?.general, state.context?.modelState, selectedTf]);

  const fetchData = async () => {
    console.log('FETCH DATA CALLED');
    const modelState = state.context.modelState || {};
    const selectedProjectId = state.context?.selectedIds?.general;

    const formattedData = Object.entries(modelState).map(([model, projects]) => {
      const projectData = projects[selectedProjectId];
      if (!projectData) return null;

      const data = projectData[selectedTf];
      if (!data) return null;

      const residual = data?.residual;
      
      return {
        label: `${model}`,
        tf: selectedTf,
        value: residual !== undefined ? `${Math.abs(residual).toFixed(2)}%` : '0%',
        trend: residual !== undefined ? (residual > 0 ? 'up' : 'down') : 'N/A',
      };
    });

    console.log('Formatted data:', formattedData);
    setData(formattedData);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const selectOption = (projectId) => {
    send({
      type: 'UPDATE_MODEL_AND_PROJECT',
      payload: {
        type: 'general',
        projectId,
      },
    });
    // send({type:"UPDATE_MODEL_STATE"})
    setIsOpen(false);
  };
  const navigate = useNavigate();
  const handleCardClick = (model) => {
    const projectID = state.context?.selectedIds?.general;
    navigate({
      pathname: '/model',
      search: createSearchParams({
        model,
        projectID,
      }).toString(),
    });
  };
  const toggleTimeframeDropdown = () => {
    setIsTimeframeOpen(!isTimeframeOpen);
  };
  const selectTimeframe = (tf) => {
    send({
      type: 'UPDATE_TIMEFRAME',
      payload: tf,
    });
    setSelectedTf(tf);
    setIsTimeframeOpen(false);
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headingContainer}>
            <div className={styles.heading}>General Overview </div>
            <Tooltip id='general-overview' page='home-page' />
          </div>
          <div className={styles.dropdownsContainer}>
            <div className={styles.dropdownContainer}>
              <div className={styles.dropdown} onClick={toggleDropdown}>
                <span className={styles.selectedOption}>{state.context?.selectedIds?.general}</span>
                <span className={styles.arrow}>
                  {isOpen ? (
                    <img className={styles.invertedArrow} src='/images/arrowDown.svg' alt='arrow down' />
                  ) : (
                    <img src='/images/arrowDown.svg' alt='arrow down' />
                  )}
                </span>
              </div>
              {isOpen && (
                <div className={styles.dropdownMenu}>
                  {state.context.projectIds.uniqueProjectIds.map((option) => (
                    <div key={option} className={styles.dropdownItem} onClick={() => selectOption(option)}>
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.dropdownContainer}>
              <div className={styles.dropdown} onClick={toggleTimeframeDropdown}>
                <span className={styles.selectedOption}>{selectedTf}</span>
                <span className={styles.arrow}>
                  {isTimeframeOpen ? (
                    <img className={styles.invertedArrow} src='/images/arrowDown.svg' alt='arrow down' />
                  ) : (
                    <img src='/images/arrowDown.svg' alt='arrow down' />
                  )}
                </span>
              </div>
              {isTimeframeOpen && (
                <div className={styles.dropdownMenu}>
                  {state.context.projectIds.tf.map((tf) => (
                    <div key={tf} className={styles.dropdownItem} onClick={() => selectTimeframe(tf)}>
                      {tf}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.cardContainer}>
          {data.map((item, index) => (
            <div key={index} className={styles.card} onClick={() => handleCardClick(item?.label)}>
              {item?.value !== '0%' && (
                <div className={styles.gradient}>
                  <img src={item?.trend === 'up' ? '/images/cardBgGreen.png' : '/images/cardBgRed.svg'} alt='card background' />
                </div>
              )}
              <div className={styles.cardContentContainer}>
                <div className={styles.cardDetails}>
                  <div
                    className={item?.value === '0%' ? styles.valueGrey : item?.trend === 'up' ? styles.valueGreen : styles.valueRed}
                    style={{
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}>
                    {item?.value}
                  </div>
                  <div className={styles.collectionName}>{item?.label}</div>
                </div>
                {item?.value !== '0%' && (
                  <div className={styles.graph}>
                    <img src={item?.trend === 'up' ? '/images/graphGreen.svg' : '/images/graphred.svg'} alt='graph' />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default GeneralOverview;
