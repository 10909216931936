import React, { useEffect, useState, useContext } from "react";
import styles from "./Ads.module.css";
import { useErrorContext } from "../../context/ErrorContext";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";

const Ads = () => {
  const [error, setError] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [adData, setAdData] = useState(null);
  const { axiosInstance } = useErrorContext();
  const { state } = useContext(AuthContext);

  useEffect(() => {
    const loadAdData = async () => {
      try {
        if (state.context?.Ads) {
          const resolvedAds = await state.context.Ads;
          setAdData(resolvedAds);
          // setIsLoading(false);
        }
      } catch (err) {
        console.error('Failed to load ad data:', err);
        setError(err);
        // setIsLoading(false);
      }
    };

    loadAdData();
  }, [state.context?.Ads]);

  const handleAdClick = async () => {
    try {
      // Track the click using query parameters
      await axiosInstance.post(`ad_click?ad_id=${adData?.id}&source=dashboard`);
    } catch (err) {
      console.error('Failed to track ad click:', err);
    }
    window.open(adData?.url, "_blank", "noopener");
  };

  const convertBase64ToImageUrl = (base64String) => {
    console.log('processing image')
    const imageData = atob(base64String);
    const arrayBuffer = new ArrayBuffer(imageData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < imageData.length; i++) {
      uint8Array[i] = imageData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
    return URL.createObjectURL(blob);
  };

  if (error) {
    return <div className={`${styles.adsWrapper} ${styles.error}`}></div>;
  }


  if (adData?.img || adData?.text) {
    return (
      <div className={styles.gradient} onClick={handleAdClick}>
        <div className={styles.adsWrapper}>
          {adData.img && (
            <img 
              src={convertBase64ToImageUrl(adData.img)} 
              alt={adData.text} 
              className={styles.adImage} 
            />
          )}
          <div className={styles.textDiv}>{adData.text}</div>
        </div>
      </div>
    );
  }

  return null;
};

export default Ads;