import React from 'react';
import { EXTERNAL_LINKS as SOCIAL_MEDIA_LINKS } from '../../constants/constants';
import styles from './SocialButtons.module.css';

const SocialButtons = () => {
  return (
    <div className={styles.container}>
      <a href={SOCIAL_MEDIA_LINKS.X} target='_blank' rel='noopener noreferrer'>
        <img
          className={styles.socialIcon}
          src='/images/twitter.svg'
          alt='X'
        />
      </a>
      <a href={SOCIAL_MEDIA_LINKS.Telegram} target='_blank' rel='noopener noreferrer'>
        <img
          className={styles.socialIcon}
          src='/images/telegram.svg'
          alt='Telegram'
        />
      </a>
      <a href={SOCIAL_MEDIA_LINKS.Docs} target='_blank' rel='noopener noreferrer'>
        <img
          className={styles.docsIcon}
          src='/images/docsIcon.svg'
          alt='Docs'
        />
      </a>
    </div>
  );
};

export default SocialButtons;