import React, { useState, useEffect, useContext } from 'react';
import styles from './Strategies.module.css';
import Tooltip from '../Tooltip/Tooltip';
import { MARKET_OVERVIEW_INIT, STRATEGIES_MODEL } from '../../constants/constants';

import { useDisconnect } from '@web3modal/ethers/react';
import { AuthContext } from '../../AuthWrapper/AuthWrapper';
import { fixToTwoDecimals } from '../../utils/helpers';

function Strategies({ dashboardState, initialProjectId }) {
  const [data, setData] = useState(MARKET_OVERVIEW_INIT);
  const [projectIds, setProjectIds] = useState([]);
  // const [collectionNames, setCollectionNames] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'winrate', direction: 'desc' });
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [tableClass, setTableClass] = useState('');
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);
  
  useEffect(() => {
    if (dashboardState) {
      const extractProjectIds = (dashboardState) => {
        const projectIdsSet = new Set();
        Object.values(dashboardState).forEach((projectList) => {

          Object.keys(projectList)?.forEach((projectId) => projectIdsSet.add(projectId));
        });
        return Array.from(projectIdsSet);
      };

      const uniqueProjectIds = extractProjectIds(dashboardState);
      setProjectIds(uniqueProjectIds || []);
      if (uniqueProjectIds.length > 0) {
        send({
          type: "UPDATE_MODEL_AND_PROJECT",
          payload: {
            type: "strategy",
            projectId: initialProjectId || uniqueProjectIds[0],
          }
        });
      }
    } else {
      // Reset state when dashboardState is null
      setProjectIds([]);
      // send({
      //   type: "UPDATE_PROJECT_ID",
      //   payload: {
      //     type:"strategy",
      //     projectId: null,
      //   },
      // });
      setData(MARKET_OVERVIEW_INIT);
      setIsLoading(false);
    }
  }, [dashboardState, initialProjectId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = state.context?.strategyState;

        const formattedData = Object.entries(result)
          .filter(
            ([_, data]) =>
              data[ state.context?.selectedIds?.historical] &&
              !data[ state.context?.selectedIds?.historical].error
          )
          ?.map(([collectionName, data]) => {
            const projectData = data[ state.context?.selectedIds?.historical];

            return {
              collectionName,
              sharpe: projectData?.[state?.context?.modelStateFilter?.tf].sharpe,
              sortino: projectData?.[state?.context?.modelStateFilter?.tf].sortino,
              winrate: projectData?.[state?.context?.modelStateFilter?.tf].winrate,
            };
          });
        //console.log(formattedData);

        sortData(sortConfig.key, sortConfig.direction, formattedData);
        if (formattedData.length > 5) {
          setTableClass(styles.largeTable);
        } else {
          setTableClass("");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (dashboardState &&  state.context?.selectedIds?.historical && state.context?.strategyState && state?.context?.modelStateFilter?.tf) {

      fetchData();
    }
  }, [ state.context?.selectedIds?.historical, state.context?.strategyState,state?.context?.modelStateFilter?.tf]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectProjectId = (projectId) => {
    send({
      type: "UPDATE_MODEL_AND_PROJECT",
      payload: {
        type: "strategy",
        projectId,
      }
    });
    setIsOpen(false);
  };

  const sortData = (key, direction = 'desc', dataToSort = data) => {
    const sortedData = [...dataToSort].sort((a, b) => {
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const toggleSortDirection = (key) => {
    let direction = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'asc';
    }
    sortData(key, direction);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? (
        <img src='/images/tableSortArrow.svg' alt='Sort ascending' className={styles.sortIcon} />
      ) : (
        <img src='/images/tableSortArrow.svg' alt='Sort descending' className={`${styles.sortIcon} ${styles.inverted}`} />
      );
    }
    return <img src='/images/tableSortArrow.svg' alt='Sort' className={`${styles.sortIcon} ${styles.inactive}`} />;
  };

  const columns = STRATEGIES_MODEL;

  const formatValue = (key, value) => {
    if (value === '--' || value === null || value === undefined) return '--';

    if (key === 'collectionName') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }

    let numValue = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numValue)) return 'N/A';

    switch (key) {
      case 'sharpe':
        return fixToTwoDecimals(numValue, 4);
      case 'sortino':
        return fixToTwoDecimals(numValue, 4);
      case 'winrate':
        return `${fixToTwoDecimals(numValue, 2, true)}%`;
      default:
        return value;
    }
  };

  const getResidualClass = (residual) => {
    if (residual === '--') return '';
    const numResidual = parseFloat(residual);
    return !isNaN(numResidual) && numResidual >= 0 ? styles.positive : styles.negative;
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Strategies</div>
            <Tooltip id="strategies" page="model-page" />
          </div>

          {/* <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleDropdown}>
              <span className={styles.selectedOption}>
                { state.context?.selectedIds?.historical}
              </span>
              <span className={styles.arrow}>
                {isOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {projectIds?.map((projectId) => (
                  <div
                    key={projectId}
                    className={styles.dropdownItem}
                    onClick={() => selectProjectId(projectId)}
                  >
                    {projectId}
                  </div>
                ))}
              </div>
            )}
          </div> */}
        </div>
        <div className={`${styles.tableContainer} ${tableClass}`}>
          <table className={styles.table}>
            <thead>
              <tr>
                {columns.map(({ key, label, sortable }) => (
                  <th
                    key={key}
                    onClick={
                      sortable ? () => toggleSortDirection(key) : undefined
                    }
                    aria-sort={
                      sortConfig.key === key ? sortConfig.direction : "none"
                    }
                  >
                    {label} {sortable && getSortIcon(key)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index} className={styles.tableRow}>
                  {columns.map(({ key }) => (
                    <td
                      key={key}
                      className={
                        key === "residual" ? getResidualClass(item[key]) : ""
                      }
                    >
                      {formatValue(key, item[key])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Strategies;