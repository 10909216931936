import axios from 'axios';
import { BASE_URL } from '../constants/constants'; // Use relative path
import {PERSIST_KEY} from "../constants/constants.js"
// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

export const createAxiosInstance = (setError) => {
  const instance = axios.create({
    baseURL: BASE_URL,
  });

  instance.interceptors.request.use(
    (config) => {
      const authState = JSON.parse(localStorage.getItem(PERSIST_KEY))?.context;

      const accessToken = authState?.accessToken;
      const ip_address = authState?.ip_address;
      const wallet_address = authState?.wallet_address;
      const signature = authState?.signature;

      if (accessToken) {
        config.headers['Authorization'] = `${accessToken}`;
      }

      if (ip_address) {
        config.headers['X-IP-Address'] = ip_address;
      }

      if (wallet_address) {
        config.headers['X-Wallet-Address'] = wallet_address;
      }

      if (signature) {
        config.headers['X-Signature'] = signature;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      setError(error.response?.data?.detail || error.message);
      return Promise.reject(error);
    }
  );

  return instance;
};