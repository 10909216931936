import React from 'react';
import styles from './Dropdown.module.css'; // Create a CSS module for styles if needed

const Dropdown = ({ selectedOption, options, onSelect, isOpen, toggleDropdown }) => {
  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdown} onClick={toggleDropdown}>
        <span className={styles.selectedOption}>{selectedOption}</span>
        <span className={styles.arrow}>
          {isOpen ? (
            <img
              className={styles.invertedArrow}
              src="/images/arrowDown.svg"
              alt="arrow down"
            />
          ) : (
            <img src="/images/arrowDown.svg" alt="arrow down" />
          )}
        </span>
      </div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {options.map((option) => (
            <div
              key={option}
              className={styles.dropdownItem}
              onClick={() => onSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;