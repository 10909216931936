import React, { useEffect, useState, useContext } from 'react';
import Plot from 'react-plotly.js';
import ToolTip from '../Tooltip/Tooltip';
import styles from './PerformanceHeatmap.module.css';
import { fixToTwoDecimals } from '../../utils/helpers';
import { CUSTOM_HEATMAP_COLORS, PERFORMANCE_HEATMAP_INITIAL, METRICS } from '../../constants/constants';
import { AuthContext } from '../../AuthWrapper/AuthWrapper';

function PerformanceHeatmap() {
  const [data, setData] = useState(PERFORMANCE_HEATMAP_INITIAL);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [heatmapData, setHeatmapData] = useState(PERFORMANCE_HEATMAP_INITIAL);
  const [selectedMetric, setSelectedMetric] = useState(METRICS[0]);
  const [selectedTf, setSelectedTf] = useState('low');
  const [isMetricOpen, setIsMetricOpen] = useState(false);
  const [isTimeframeOpen, setIsTimeframeOpen] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const { state, send } = useContext(AuthContext);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchStrategyState = () => {
      try {
        setLoading(true);

        const data = state.context?.strategyState;

        setData(data);
        processData(data);
      } catch (err) {
        console.error('Error fetching strategy state:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStrategyState();
  }, [state.context?.strategyState]);

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data, selectedMetric, state.context.strategyStateFilter.tf]);

  const processData = (data) => {
    if (!data) return;

    const collections = Object.keys(data);
    const projectIds = Object.keys(data[collections[0]] || {});

    const heatmapValues = collections.map((cid) =>
      projectIds.map((pid) => {
        const strategyData = data[cid]?.[pid]?.[state.context.strategyStateFilter.tf];
        return strategyData ? strategyData[selectedMetric] : 0;
      })
    );

    const allValues = heatmapValues.flat().filter((v) => v !== 0);
    
    let minVal, maxVal;
    if (selectedMetric === 'winrate') {
      // For winrate, use actual min and max
      minVal = Math.min(...allValues);
      maxVal = Math.max(...allValues);
    } else {
      // For other metrics, use symmetric scale
      const maxAbsValue = Math.max(...allValues.map(Math.abs));
      minVal = -maxAbsValue;
      maxVal = maxAbsValue;
    }

    setHeatmapData({
      z: heatmapValues,
      x: projectIds,
      y: collections,
      type: 'heatmap',
      colorscale: CUSTOM_HEATMAP_COLORS,
      showscale: true,
      colorbar: {
        title: {
          text: selectedMetric,
          side: 'right',
          x: 1.05,
        },
      },
      text: heatmapValues.map((row) =>
        row.map((value) => {
          const formattedValue = fixToTwoDecimals(value ?? 0, 2, true);
          return ['sharpe', 'sortino'].includes(selectedMetric) 
            ? formattedValue
            : `${formattedValue}%`;
        })
      ),
      texttemplate: '%{text}',
      hoverinfo: 'text',
      zmin: minVal,
      zmax: maxVal,
    });
  };

  // console.log(heatmapData, 'heatmapData');

  const getPlotDimensions = () => {
    if (dimensions.width > 1920) {
      return { width: dimensions.width * 0.37, height: dimensions.width * 0.25 };
    // } else if (dimensions.width < 900) {
    //   return { width: dimensions.width * 0.8, height: dimensions.width * 0.7 };
    } else if (dimensions.width < 1415) {
      return { width: '100%', height: '100%' };
    } else if (dimensions.width < 1540) {
      return { width: 500, height: 450 };
    } else if (dimensions.width < 1650) {
      return { width: 540, height: 450 };
    } else {
      return { width: 650, height: 450 };
    }
  };

  const plotDimensions = getPlotDimensions();

  const toggleMetricDropdown = () => {
    setIsMetricOpen(!isMetricOpen);
  };

  const selectMetric = (metric) => {
    setSelectedMetric(metric);
    setIsMetricOpen(false);
  };

  const toggleTimeframeDropdown = () => {
    setIsTimeframeOpen(!isTimeframeOpen);
  };

  const selectTimeframe = (tf) => {
    setSelectedTf(tf);
    setIsTimeframeOpen(false);
  };

  if (loading) {
    return <div className={styles.loadingContainer}>Loading...</div>;
  }

  if (error) {
    return <div className={styles.errorContainer}>Error: {error}</div>;
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainer}>
          <div className={styles.headerText}>Performance</div>
          <ToolTip id='performance' page='strategy-page' />
        </div>
        <div className={styles.dropdownContainer}>
          {/* Metric Dropdown */}
          <div className={styles.dropdown} onClick={toggleMetricDropdown}>
            <span className={styles.selectedOption}>{selectedMetric}</span>
            <span className={styles.arrow}>
              {isMetricOpen ? (
                <img className={styles.invertedArrow} src='/images/arrowDown.svg' alt='arrow down' />
              ) : (
                <img src='/images/arrowDown.svg' alt='arrow down' />
              )}
            </span>
          </div>
          {isMetricOpen && (
            <div className={styles.dropdownMenu}>
              {METRICS.map((metric) => (
                <div key={metric} className={styles.dropdownItem} onClick={() => selectMetric(metric)}>
                  {metric}
                </div>
              ))}
            </div>
          )}

          {/* Timeframe Dropdown */}
          {/* <div className={styles.dropdown} onClick={toggleTimeframeDropdown}>
            <span className={styles.selectedOption}>{selectedTf}</span>
            <span className={styles.arrow}>
              {isTimeframeOpen ? (
                <img className={styles.invertedArrow} src='/images/arrowDown.svg' alt='arrow down' />
              ) : (
                <img src='/images/arrowDown.svg' alt='arrow down' />
              )}
            </span>
          </div>
          {isTimeframeOpen && (
            <div className={styles.dropdownMenu}>
              {state.context?.projectIds?.tf?.map((tf) => (
                <div
                  key={tf}
                  className={styles.dropdownItem}
                  onClick={() => selectTimeframe(tf)}>
                  {tf}
                </div>
              ))}
            </div>
          )} */}
        </div>
      </div>

      <div className={styles.plotContainer}>
        <Plot
          data={[heatmapData]}
          layout={{
            width: dimensions.width < 900 ? '' : plotDimensions.width,
            height: dimensions.width < 900 ? '' : plotDimensions.height,
            autosize: true,
            xaxis: {
              title: { text: 'Assets' },
              color: '#fff',
              showgrid: false,
              zeroline: false,
            },
            yaxis: {
              title: { text: 'Models' },
              color: '#fff',
              showgrid: false,
              zeroline: false,
            },
            paper_bgcolor: 'rgba(44, 44, 44, 0.0)',
            plot_bgcolor: 'rgba(44, 44, 44, 0)',
            font: { color: '#fff' },
            margin: { t: 80, pad: 6 },
          }}
          config={{
            displayModeBar: false,
            staticPlot: true,
            useResizeHandler: true,
            responsive: true,
          }}
          style={{
            width: dimensions.width < 900 ? '100%' : 'auto',
            // display: 'flex',
            // justifyContent: 'center',
            height: dimensions.width > 700 ? 'auto' : '100%',
          }}
        />
      </div>
    </div>
  );
}

export default PerformanceHeatmap;
