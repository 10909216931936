import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../AuthWrapper/AuthWrapper.js';
import withAuthenticationGuard from '../components/guards/withAuthWrapper';
import MarketOverview from '../components/MarketOverviewModel/MarketOverview.js';
import HistoricalOverviewModel from '../components/ModelHistoricalOverview/HistoricalOverview.js';
import ModelOverview from '../components/ModelOverview/ModelOverview.js';
import ModelSentiment from '../components/ModelSentiment/ModelSentiment.js';
import Strategies from '../components/StrategiesModel/Strategies.js';
import withLayout from '../components/withLayout/withLayout.jsx';
import { useSearchParams } from 'react-router-dom';
import ZscatterModel from '../components/ZscatterModel/ZscatterModel.js';

const Model = ({ fullWidth }) => {
  const [searchParams] = useSearchParams();
  const model = searchParams.get('model');
  const projectID = searchParams.get('projectID');
  const { isConnected } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);
  const isLoading = state.matches("idle") || 
                   state.matches("signingIn") || 
                   state.matches("waitingForSubscription") || 
                   state.matches("verifyingTransaction") || 
                   state.matches("loggingOut");
  
  useEffect(() => {
    if (state.matches('model') || state.matches('home') || state.matches('strategy') || state.matches('apiKey')) send({ type: 'CHECK_ROUTE' });
  }, [send]);


  const RenderLoadingOverlay = () => (
    <div className="loading-overlay" />
  );
  const renderComponents = (data) => (
    <>
      <HistoricalOverviewModel key={fullWidth} dashboardState={data} initialModel={data ? model : null} initialProjectId={data ? projectID : null} />
      <MarketOverview dashboardState={data} initialProjectId={data ? projectID : null} />
      <ModelSentiment dashboardState={data} initialProjectId={data ? projectID : null} />
      {/* <ModelOverview dashboardState={data} initialModel={data ? model : null} /> */}

      <ZscatterModel dashboardState={data} initialProjectId={data ? projectID : null} />
      {/* <Strategies dashboardState={data} initialProjectId={data ? projectID : null} /> */}
    </>
  );

  return (
    <>
      {isConnected  ? (
        renderComponents(state.context.dashboardState)
      ) : (
        <>
          {renderComponents(null)}
        </>
      )}
      {isLoading  && <RenderLoadingOverlay />}
    </>
  );
};

export default withAuthenticationGuard(Model);
