import { useDisconnect } from '@web3modal/ethers/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../AuthWrapper/AuthWrapper.js';
import { extractProjectIds } from '../../utils/helpers.js';
import Tooltip from '../Tooltip/Tooltip.js';
import Circle from './Circle.js';
import styles from './ModelSentiment.module.css';

function ModelSentiment({ dashboardState, initialProjectId }) {
  const [projectIds, setProjectIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [sentimentData, setSentimentData] = useState({});
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);
  const toggleDropdown = useCallback(() => setIsOpen((prev) => !prev), []);

  const selectProjectId = useCallback((projectId) => {
    send({
      type: "UPDATE_MODEL_AND_PROJECT",
      payload: {
        type: "sentiment",
        projectId,
      }
    });
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (dashboardState) {
      const uniqueProjectIds = extractProjectIds(dashboardState)?.uniqueProjectIds;
      setProjectIds(uniqueProjectIds);
      if (uniqueProjectIds.length > 0) {
        send({
          type: "UPDATE_MODEL_AND_PROJECT",
          payload: {
            type: "sentiment",
            projectId:
            initialProjectId ||
            (state.context?.selectedIds?.historical
              ? state.context?.selectedIds?.historical
              : uniqueProjectIds[0]),
          }
        });
      } else {
        send({
          type: "UPDATE_MODEL_AND_PROJECT",
          payload: {
            type: "sentiment",
            projectId: null,
          }
        });
      }
    } else {
      setProjectIds([]);
      send({
        type: "UPDATE_MODEL_AND_PROJECT",
        payload: {
          type: "sentiment",
          projectId: null,
        }
      });
      setSentimentData({});
      setIsLoading(false);
    }
  }, [dashboardState, initialProjectId]);


  const fetchData = async () => {
    const result = state.context?.modelState || {};

    const data = {};
    
    Object.entries(result).forEach(([model, projectsData]) => {
      Object.entries(projectsData).forEach(([projectId, projectData]) => {
        const z_residual = projectData?.[state?.context?.modelStateFilter?.tf]?.z_residual;
        if (z_residual !== undefined) {
          if (!data[projectId]) {
            data[projectId] = { bullish: 0, neutral: 0, bearish: 0, models: {} };
          }
          
          if (z_residual > 1.5) data[projectId].bullish++;
          else if (z_residual < -1.5) data[projectId].bearish++;
          else data[projectId].neutral++;
          
          data[projectId].models[model] = { 
            z_residual, 
            sentiment: getSentiment(z_residual) 
          };
        }
      });
    });

    setSentimentData(data);
  };


  useEffect(()=>{
    if(dashboardState && state?.context?.modelState && Object.keys(state?.context?.modelState).length> 0){
      fetchData()
    }
  },[state.context, dashboardState])

  useEffect(() => {
    if (dashboardState && state.context?.selectedIds?.historical) {
      // send({type:"UPDATE_MODEL_STATE"})
      fetchData();
    }
  }, [dashboardState, state.context?.selectedIds?.historical]);

  const getSentiment = (zScore) => {
    if (zScore > 1.5) return 'Bullish';
    if (zScore < -1.5) return 'Bearish';
    return 'Neutral';
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.circleBG}>
        <img src="/images/circleBG.png" alt="" />
      </div>{" "}
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Models By Sentiment</div>
            <Tooltip id="models-by-sentiment" page="model-page" />
          </div>
{/* 
          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleDropdown}>
              <span className={styles.selectedOption}>
                {state.context?.selectedIds?.historical}
              </span>
              <span className={styles.arrow}>
                {isOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {projectIds.map((projectId) => (
                  <div
                    key={projectId}
                    className={styles.dropdownItem}
                    onClick={() => selectProjectId(projectId)}
                  >
                    {projectId}
                  </div>
                ))}
              </div>
            )}
          </div> */}
        </div>
        <div className={styles.circleContainer}>
          <Circle
            sentimentCounts={
              sentimentData[state.context?.selectedIds?.historical] || {
                bullish: 0,
                neutral: 0,
                bearish: 0,
              }
            }
            projectData={sentimentData[state.context?.selectedIds?.historical]?.models || {}}
          />
        </div>
      </div>
    </div>
  );
}

export default ModelSentiment;