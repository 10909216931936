import React from 'react';
import Plot from 'react-plotly.js';
// import styles from './ZscatterModel.module.css';

function ScatterChart({ data }) {
  const config = {
    responsive: true,
    displayModeBar: true,
    modeBarButtonsToRemove: [
      'select2d', // Removes 2D selection
      'lasso2d', // Removes lasso tool
      'zoomIn2d', // Removes zoom in
      'zoomOut2d', // Removes zoom out
      'toImage',
      'resetScale2d',
    ],
    displaylogo: false, 
  };
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Plot
        data={data}
        layout={{
          autosize: true,
          title: {
            y: 0.95,
            pad: {
              t: 0,
              b: 0,
            },
          },
          xaxis: {
            title: 'Timestamp',
            gridcolor: 'rgba(255,255,255,0.1)',
            zerolinecolor: 'rgba(255,255,255,0.2)',

            gridwidth: 0.25, // Thinner gridlines
          },
          yaxis: {
            title: {
              text: 'Z-Score Residual',
              standoff: 5,
            },
            gridcolor: 'rgba(255,255,255,0.1)',
            zerolinecolor: 'rgba(255,255,255,0.2)',
            gridwidth: 0.25,
          },
          plot_bgcolor: 'transparent',
          paper_bgcolor: 'transparent',
          font: { color: '#FFFFFF' },

          height: 430,

          showlegend: true,
          legend: {
            font: { color: '#FFFFFF' },
            bgcolor: 'rgba(0,0,0,0.3)',
            orientation: 'h',
            xanchor: 'center',
            yanchor: 'bottom',
            x: 0.5,
            y: 0.95,
            traceorder: 'normal',
            itemsizing: 'constant',
            itemwidth: 40,
            ncol: 2,
          },
          margin: {
            l: 50,
            r: 50,
            t: 60,
            b: 100,
          },
        }}
        style={{ width: '100%', height: '100%' }}
        config={config}
        useResizeHandler={true}
      />
    </div>
  );
}

export default ScatterChart;
