import React, { useState, useEffect, useContext, useCallback } from 'react';
import styles from './ZscatterModel.module.css';
import Dropdown from '../Dropdown/Dropdown.jsx';
import { AuthContext } from '../../AuthWrapper/AuthWrapper.js';
import Tooltip from '../Tooltip/Tooltip.js';
import Loader from '../Loader/Loader.js';
import ScatterChart from './ScatterChart';

function ZscatterModel({ dashboardState }) {
  const [plotData, setPlotData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { state, send } = useContext(AuthContext);

  useEffect(() => {
    if (dashboardState && state.context?.modelRecent?.length > 0) {
      fetchModelData();
    }
  }, [dashboardState, state.context?.modelRecent]);

  const fetchModelData = async () => {
    setIsLoading(true);
    try {
      const data = state.context?.modelRecent;
      // console.log(data, 'data');

      const projectIds = Object.keys(data[0]).filter((key) => key !== 'timestamp');

      const traces = projectIds.map((pid) => ({
        x: data.map((entry) => entry.timestamp),
        y: data.map((entry) => entry[pid]),
        type: 'scatter',
        mode: 'lines',
        name: pid,
      }));

      setPlotData(traces);
    } catch (error) {
      console.error('Error fetching model data:', error);
      setPlotData([]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.overviewHeader}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>Recent History</div>
          <Tooltip id='recent-history' page='model-page' />
        </div>
      </div>

      <div className={styles.chartContainer}>
        <div className={styles.chartBG}>
          <img src='images/chartBG.png' alt='background' />
        </div>
        {isLoading && <Loader />}
        <ScatterChart data={plotData} />
      </div>
    </div>
  );
}

export default ZscatterModel;
